import React, { useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import moment from "moment";
moment.locale("es");

import { WEEKDAYS } from "~/constants/coupons";
import useCancelableFetch from "~/hooks/useCancelableFetch";
import usePermissions from "~/hooks/usePermissions";
import couponsAPI from "~/utils/api/v2/coupons";
import type { IExtendedCoupon } from "~/utils/interfaces/coupons";

type CouponsIndexProps = {
  id: string;
};

const CouponsIndex = ({ id }: CouponsIndexProps) => {
  const { isCouponAdmin } = usePermissions();
  const { successResponse, isLoading, retry } = useCancelableFetch<IExtendedCoupon, any>(couponsAPI.retrieve, { id });
  const [isToggling, setIsToggling] = useState<boolean>(false);

  const toggle = async () => {
    setIsToggling(true);
    await couponsAPI.toggle_active(id);
    setIsToggling(false);
    retry();
  };

  return (
    <div className="m-4 bg-examedi-white-pure p-4 border rounded-md">
      {isLoading ? (
        <div>Cargando</div>
      ) : (
        <div className="space-y-1">
          <div className="flex flex-row items-center w-full justify-between">
            <div className="flex flex-row items-center">
              <span
                className={clsx(
                  "px-4 py-2 rounded-full my-2 mr-4",
                  successResponse?.active ? "bg-examedi-green-strong" : "bg-examedi-red-error",
                )}
              >
                {successResponse?.active ? "Activo" : "Inactivo"}
              </span>
              <h3 className="inline">{successResponse?.referral_code}</h3>
            </div>
            <div className="flex flex-row items-center gap-4">
              {isCouponAdmin && (
                <>
                  <button
                    className={clsx(
                      isToggling ? "bg-examedi-gray-light" : "bg-examedi-blue-normal",
                      "text-examedi-white-pure text-sm rounded-lg block p-2.5",
                    )}
                    onClick={toggle}
                    disabled={isToggling}
                  >
                    {successResponse?.active ? "DESACTIVAR" : "ACTIVAR"}
                  </button>
                  <button
                    className="bg-examedi-blue-normal text-examedi-white-pure text-sm rounded-lg block p-2.5"
                    onClick={() => {
                      navigate(`/coupons/${id}/edit/`);
                    }}
                  >
                    EDITAR
                  </button>
                </>
              )}
            </div>
          </div>
          <h4 className="mb-4 font-semibold">
            {successResponse?.method === "percentage"
              ? `${successResponse.discount * 100}%`
              : `$${successResponse?.discount}`}
          </h4>
          <div>
            <span className="font-semibold">País: </span>
            {successResponse?.country === "cl" ? "Chile" : "México"}
          </div>
          <div>
            <span className="font-semibold">Alianza: </span>
            {successResponse?.alliance}
          </div>
          <hr className="my-4"></hr>
          <h5>Condiciones:</h5>

          <div>
            <span className="font-semibold">Solo para primera compra: </span>
            {successResponse?.only_for_first_purchase ? "✅" : "❌"}
          </div>
          <div>
            <span className="font-semibold">Fecha de inicio: </span>
            {successResponse?.begin_date ? moment.utc(successResponse?.begin_date).format("LL") : "❌"}
          </div>
          <div>
            <span className="font-semibold">Fecha de expiración: </span>
            {successResponse?.expiration_date ? moment.utc(successResponse?.expiration_date).format("LL") : "❌"}
          </div>
          <div>
            <span className="font-semibold">Usos restantes: </span>
            {successResponse?.remaining_uses ? successResponse?.remaining_uses : "∞"}
          </div>
          <div>
            <span className="font-semibold">Compra mínima: </span>
            {successResponse?.minimum_purchase !== 0 ? `$${successResponse?.minimum_purchase}` : "❌"}
          </div>
          <div>
            <span className="font-semibold">Descuento máximo: </span>
            {successResponse?.max_discount ? `$${successResponse?.max_discount}` : "❌"}
          </div>
          <hr className="my-4"></hr>
          <h5>Servicios asociados:</h5>
          <div>
            <span className="font-semibold">Telemedicina: </span>
            {successResponse?.telemedicine ? "✅" : "❌"}
          </div>
          {successResponse?.global_discount ? (
            <span className="font-semibold">Para todo servicio a domicilio ✅</span>
          ) : (
            <div>
              <span className="font-semibold">Válido solo para los tags:</span>
              <ul className="list-disc">
                {successResponse?.tags.map((tag) => (
                  <li>{tag}</li>
                ))}
              </ul>
              <span className="font-semibold">Items excluidos:</span>
              <ul className="list-disc">
                {successResponse?.excluded_items.map((item) => (
                  <li>{item.name}</li>
                ))}
              </ul>
            </div>
          )}
          <hr className="my-4"></hr>
          <h5>Habilitado los dias:</h5>
          <div className="flex flex-col gap-y-2">
            {Object.entries(WEEKDAYS).map(([key, value]) => (
              <div className="w-full flex gap-x-2">
                <div className="capitalize">{value}</div>
                {successResponse?.disabled_days?.includes(key) ? "❌" : "✅"}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponsIndex;
