import { useEffect, useRef, useState } from "react";

import type { PaginatedResponse, RequestResponse } from "~/utils/interfaces/request";

function useCancelableFetch<T, S>(
  fn: (args: S) => Promise<RequestResponse<T> | PaginatedResponse<T>>,
  originalArgs: S,
  skipFirstRequest?: boolean,
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<RequestResponse<T> | PaginatedResponse<T>>();
  const [successResponse, setSuccessResponse] = useState<T | PaginatedResponse<T> | null>(null);
  const [errorResponse, setErrorResponse] = useState<any>();
  const abortController = useRef<AbortController | null>(null);

  function retry(args?: S, skipCancelPrevRequest?: boolean) {
    fetchFn(args, skipCancelPrevRequest);
  }

  function cancel() {
    if (abortController?.current?.signal.aborted === false) {
      abortController.current.abort();
    }
  }

  async function fetchFn(newArgs?: S, skipCancelPrevRequest?: boolean) {
    if (!skipCancelPrevRequest) cancel();
    setSuccessResponse(null);
    setErrorResponse(null);
    abortController.current = new AbortController();
    setIsLoading(true);
    const args = newArgs || originalArgs;
    const response = await fn({ ...args, signal: abortController.current.signal });
    setResponse(response);
    if (response.error) {
      if (response.error.message === "canceled") {
        // do nothing
      } else {
        setErrorResponse(response.error);
        setIsLoading(false);
      }
    } else {
      setSuccessResponse("data" in response ? response.data : response);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!skipFirstRequest) fetchFn();
    return () => {
      cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, response, successResponse, errorResponse, cancel, retry };
}

export default useCancelableFetch;
