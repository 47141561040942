import api from "~/utils/api/api";
import type { CreateEditCoupon, IBaseCoupon, IExtendedCoupon } from "~/utils/interfaces/coupons";
import type { Locale } from "~/utils/interfaces/Locale";
import type { PaginatedResponse, RequestError } from "~/utils/interfaces/request";

class CouponsAPI {
  list = async ({
    search,
    active,
    country,
    method,
    page,
  }: {
    search?: string;
    active?: boolean;
    country?: Locale;
    method?: "percentage" | "amount";
    page?: number;
  } = {}) => {
    try {
      const res = await api.get<PaginatedResponse<IBaseCoupon>>(`v2/coupons/`, {
        params: { search, active, country, method, page },
      });
      return res.data;
    } catch (error) {
      return { error } as RequestError;
    }
  };

  create = async ({ data }: { data: CreateEditCoupon }) => {
    try {
      const res = await api.post("v2/coupons/", data);
      return res.data;
    } catch (error) {
      return { error } as RequestError;
    }
  };

  partialUpdate = async ({ id, data }: { id: string; data: CreateEditCoupon }) => {
    try {
      const res = await api.patch(`v2/coupons/${id}/`, data);
      return res.data;
    } catch (error) {
      return { error } as RequestError;
    }
  };

  retrieve = async ({ id }: { id: string }) => {
    try {
      const res = await api.get<IExtendedCoupon>(`v2/coupons/${id}/`);
      return res;
    } catch (error) {
      return { error } as RequestError;
    }
  };

  toggle_active = async (id: string) => {
    try {
      const res = await api.post(`v2/coupons/${id}/toggle_active/`);
      return true;
    } catch (error) {
      return undefined;
    }
  };
}

const couponsAPI = new CouponsAPI();

export default couponsAPI;
